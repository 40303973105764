import { Subject } from "rxjs";
import { PaywallExceptionDTO } from "shared-module";

export type PaywallModalShowPayload = {
    show: boolean,
    exception?: PaywallExceptionDTO,
    onBeforeCheckout?: () => Promise<void> | void
}

const ShowPaywalSubject = new Subject<PaywallModalShowPayload>();

function showPaywall(exception?: PaywallExceptionDTO, onBeforeCheckout?: PaywallModalShowPayload['onBeforeCheckout']) {
    ShowPaywalSubject.next({ show: true, exception, onBeforeCheckout });
}

export const PaywalModalService = {
    getPaywallObservable: () => ShowPaywalSubject.asObservable(),
    showPaywall,
    hidePaywall: () => ShowPaywalSubject.next({ show: false } as PaywallModalShowPayload),
}