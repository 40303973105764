import { NotificationDTO } from "shared-module";
import config from '../../../../config/serverless-config.json';
import apiService from '../../../common/services/api.service';
import { ListResult } from "../../../common/types/ListResult";

export class NotificationService {
    /**
     * Will add the suffix after {stage}/{entityBase}/
     * @param {string} suffix The rest of the url WITHOUT slash at the begining
     */
    protected buildUrl(suffix?: string) {
        if (!suffix?.trim())
            return `${config.stage}/notifications`;

        return `${config.stage}/notifications/${suffix}`;
    }

    list = async (after?: string) => {
        const result = await apiService.get<ListResult<NotificationDTO>>(this.buildUrl(), { params: { after } });
        return result.data;
    }

    delete = async (id: string) => {
        await apiService.delete(this.buildUrl(id));
    }

    markAsRead = async (ids: string[]) => {
        await apiService.post(this.buildUrl('mark-as-read'), { ids })
    }

    hasNew = async ()=>{
        return (await apiService.get<boolean>(this.buildUrl('has-new'))).data;
    }
}