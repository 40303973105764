import { EntityWithTagBaseService } from "../../../common/services/entityWithTag.service";
import apiService from '../../../common/services/api.service';
import { ListResult } from "../../../common/types/ListResult";
import { EventDTO } from "shared-module/dist/types/EventDTO";
import config from '../../../../config/serverless-config.json';
import { getUtcValueFromSeparateLocalDateAndTime } from "../../../../utils/timezoneUtils";
import { addDays } from "date-fns";
import { EventFormType } from "../components/EventForm/EventFormType";
import { BuyerFromEventDTO } from "shared-module";


export class EventsService extends EntityWithTagBaseService<EventDTO, EventFormType>{

    constructor() {
        super('events');
    }

    getPortfolioEvents = async (portfolioId: string, after?: string, includeCollaborations = true) => {
        const result = await apiService.get<ListResult<EventDTO>>(this.buildUrl(), {
            params: {
                after,
                portfolioId
            }
        });
        result.data.items.forEach(this.adaptData);
        return result.data;
    }

    toggleBookmark = async (eventId: string) => {
        const result = await apiService.post<{ bookmarked: boolean }>(this.buildUrl(`${eventId}/toggle-bookmark`));
        return result.data;
    }

    search = async (filters: any, skip: number) => {
        filters = { ...filters }
        if (filters.dateStart)
            filters.dateStart = getUtcValueFromSeparateLocalDateAndTime(filters.dateStart).toISOString();
        if (filters.dateEnd)
            filters.dateEnd = addDays(getUtcValueFromSeparateLocalDateAndTime(filters.dateEnd), 1).toISOString();

        filters.skip = skip;

        const result = await apiService.post<ListResult<EventDTO>>(`/${config.stage}/search/events`, filters);
        result.data.items.forEach(this.adaptData);

        return result.data
    }

    getBuyersForEvent = async (eventId: string, cursor?: string) => {
        const result = await apiService.get<ListResult<BuyerFromEventDTO>>(`/${config.stage}/marketplace/events/${eventId}/buyers`, {
            params: {
                cursor
            }
        });

        return result.data;
    }
}