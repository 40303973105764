import apiService from "./api.service"
import config from "../../../config/serverless-config.json";
import { /*CheckoutCartDTO,*/ CheckoutDTO, TransactionDTO } from "shared-module";
import { AxiosError } from "axios";
import { CheckoutExceptionResponse } from "../exceptions/CheckoutExceptionResponse";
import { ListResult } from "../types/ListResult";



export class MarketplaceBuyerService {
    private static checkoutSessionIdStorageKey = "stripeCheckoutSessionId";
    private static transactionToAssignToUserStorageKey = "transactionToAssignToUser";
    /**
     * Will add the suffix after {stage}/{entityBase}/
     * @param {string[]} suffixes The rest of the url WITHOUT slash at the beginning
     */
    protected static buildUrl = (...suffixes: string[]) => {
        if (!suffixes.map(x => x.trim()).filter(x => x).length)
            return `${config.stage}/checkout/`;

        return `${config.stage}/checkout/${suffixes.map(x => x.trim()).join('/')}`;
    }

    // static createSetupIntent() {
    //     return apiService.post(this.buildUrl('create-setup-intent')).then(x => {
    //         return x.data as { clientSecret: string };
    //     });
    // }

    // static async buyCartContent(data: CheckoutCartDTO) {
    //     return apiService.post(this.buildUrl('checkout-cart'), data)
    //         .then(x => x.data)
    //         .catch((x: AxiosError<CheckoutExceptionResponse>) => {
    //             if (x.response?.data?.type == 'checkoutException')
    //                 return x.response.data;
    //             throw x;
    //         })
    // }

    static async listTransactions(cursor?: string) {
        const result = await apiService.get<ListResult<TransactionDTO>>(this.buildUrl('transactions'), { params: { cursor } })
        return result.data;
    }

    // static async calculateTaxes(data: CheckoutCartDTO) {
    //     return apiService.post(this.buildUrl('taxes'), data)
    //         .then(x => x.data);
    // }

    static async createCheckoutSession(data: CheckoutDTO) {
        return apiService.post(this.buildUrl('create-session'), data)
            .then(x => x.data as { checkoutUrl: string });
    }

    static async getCheckoutSession(stripeSessionId: string, sellerStripeId: string) {
        return apiService.get<{ transaction?: TransactionDTO }>(this.buildUrl('session', stripeSessionId), { params: { sellerStripeId } })
            .then(x => x.data);
    }

    static storeStripeCheckoutSessionIdInSessionStorage(sessionId: string) {
        sessionStorage.setItem(this.checkoutSessionIdStorageKey, sessionId);
    }

    static getStripeCheckoutSessionIdFromSessionStorage() {
        return sessionStorage.getItem(this.checkoutSessionIdStorageKey);
    }

    static removeStripeCheckoutSessionIdFromSessionStorage() {
        sessionStorage.removeItem(this.checkoutSessionIdStorageKey);
    }

    static storeTransactionToAssignToUser(transactionId: string) {
        sessionStorage.setItem(this.transactionToAssignToUserStorageKey, transactionId);
    }

    static getTransactionToAssingToUser() {
        return sessionStorage.getItem(this.transactionToAssignToUserStorageKey);
    }

    static removeTransactionToAssignToUser() {
        sessionStorage.removeItem(this.transactionToAssignToUserStorageKey);
    }


    static assignGuestTransactionToUser() {
        const sessionId = this.getStripeCheckoutSessionIdFromSessionStorage();
        const transactionId = this.getTransactionToAssingToUser();

        if (sessionId && transactionId) {
            apiService.post(this.buildUrl('transactions', transactionId, 'assign'), { sessionId })
                .then(x => {
                    this.removeStripeCheckoutSessionIdFromSessionStorage();
                    this.removeTransactionToAssignToUser();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
}