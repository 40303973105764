// export const hashedPasswordWall = '480d3f4944006e5c97bb3211e9ec74dffc990133068fb85b68b1f13304e5387a';//first
export const hashedPasswordWall = 'a39d78d5f325ab3866ebfbaa3edacad055d3836b6f7f9f6d282b5249f7d01ee1';

type subFunc = (show: boolean) => void;
export const passwordWallStorageKey = "hashedPasswordWall";

export class PasswordWallSubscription {
    private static subscribers: subFunc[] = [];

    static subscribe = (fn: subFunc) => {
        if (!this.subscribers.some(x => x === fn))
            this.subscribers.push(fn);
        return this.unsubscribe(fn);
    }
    private static unsubscribe = (fn: subFunc) => {
        return () => {
            this.subscribers = this.subscribers.filter(x => x !== fn);
        }
    }

    static next = (show: boolean) => {
        this.subscribers.forEach(x => x(show));
    }

}

async function digestMessage(message: string) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
    return hashHex;
}

export async function validateHash(text: string, hashed: string) {
    const hashedText = await digestMessage(text);
    return hashedText === hashed;
}

export async function validatePassword(pwd: string) {
    if (await validateHash(pwd, hashedPasswordWall)) {
        localStorage.setItem(passwordWallStorageKey, await digestMessage(hashedPasswordWall))
        return true;
    }

    return false;
}