import React from 'react';
import styled from 'styled-components';

type Props = {
    size?: number;
    circleCount?: number;
    divide?: number;
    baseRadius?: number;
    fillColor?: string;
};

function CustomSpinner({ size = 60, circleCount = 8, divide = 2, baseRadius = 2, fillColor = 'black' }: Props) {
    const circles = [];
    const increment = 2 * Math.PI / circleCount; // Calculate the angle increment
    const centerX = size / 2;
    const centerY = size / 2;
    const radius = (size * 0.8) / 2 - baseRadius;

    for (let i = 0; i < circleCount; i++) {
        const currentRadius = baseRadius + (i / divide);
        const angle = i * increment;
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        const animationDuration = 1; // Duration of the opacity animation in seconds
        const animationDelay = (i * animationDuration) / circleCount; // Delay each circle's animation based on its index

        circles.push(
            <StyledCircle
                key={i}
                cx={x}
                cy={y}
                r={currentRadius}
                fill={fillColor}
                stroke="none"
                strokeLinecap="round"
                $animationDuration={animationDuration}
                $animationDelay={animationDelay}
            />
        );
    }

    return (
        <svg width={size} height={size}>
            {circles}
        </svg>
    );
}

type StyledCircleProps = {
    $animationDuration: number;
    $animationDelay: number;
};

const StyledCircle = styled.circle<StyledCircleProps>(({ $animationDuration, $animationDelay }) => ({
    opacity: 0,
    transform: 'rotate(-45deg)',
    animation: `fadeAnimation ${$animationDuration}s infinite ease ${$animationDelay}s both`,
    transformOrigin: 'center',

    '@keyframes fadeAnimation': {
        '0%': {
            opacity: 0.1,
        },
        '70%': {
            opacity: 0.35,
        },
        '100%': {
            opacity: 1,
        },
    },
}));

export default CustomSpinner;
