import styled from 'styled-components';

export const StyledButton = styled.button<{ $fontSize?: string }>(({ $fontSize }) => ({
    borderRadius: '30px !important',
    border: '1px solid var(--db-black) !important',
    backgroundColor: 'var(--db-primary) !important',
    color: 'var(--db-black)',
    fontSize: $fontSize ? $fontSize : '18px',
    padding: '8px 48px',
}));
