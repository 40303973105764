import { TagModel, TagTypes } from '../../../models/TagModel';
import apiService from './api.service'
import { BaseCRUDService } from './baseCRUD.service';


export interface ITagService {
    getTagsByType: (entityId: string, tagType: TagTypes, lang: string) => Promise<TagModel[]>
    updateTags: (tagType: TagTypes, tags: TagModel[], entityId: string) => Promise<boolean>
}

export abstract class EntityWithTagBaseService<TEntity, TUpsertModel = Partial<TEntity>, TFilterModel = any>
    extends BaseCRUDService<TEntity, TUpsertModel, TFilterModel>
    implements ITagService {

    getTagsByType = async (entityId: string, tagType: TagTypes, lang: string) => {
        const x = await apiService.get<TagModel[]>(this.buildUrl(`${entityId}/${tagType}`), { params: { lang } });
        return x.data;
    }

    updateTags = async (tagType: TagTypes, tags: TagModel[], entityId: string) => {
        const x = await apiService.put<boolean>(this.buildUrl(`${entityId}/${tagType}`), {
            tags
        });
        return x.data;
    }
}