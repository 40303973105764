import { hashedPasswordWall, passwordWallStorageKey, PasswordWallSubscription, validateHash } from "./validatePassword";
import config from '../../../../config/serverless-config.json';


export async function getShowWall() {
    const stored = localStorage.getItem(passwordWallStorageKey);
    let showWall = config.stage === 'dev';

    if (showWall && stored) {
        showWall = await validateHash(hashedPasswordWall, stored) === false;
        PasswordWallSubscription.next(showWall);
    }

    return showWall;
}