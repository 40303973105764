import React, { useContext, useEffect, useRef } from "react";
import { PropsWithChildren, createContext } from "react";
import { NotificationDTO } from "shared-module";
import { NotificationService } from "../services/NotificationsService";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { getListResultPageParam } from "../../../common/types/ListResult";
import authContext from "../../../auth/context/AuthContext";
import wait from "../../../../utils/wait";
import { useCachedTagListQuery } from "../../../common/hooks/useCachedTagListQuery";

const notificationContextInitialValue = {
    notifications: [] as NotificationDTO[],
    refetch: () => { },
    isLoading: true,
    nextPage: () => { },
    hasNextPage: false,
    hasNewNotifications: false,
}

export const NotificationContext = createContext(notificationContextInitialValue);


export function NotificationProvider({ children }: PropsWithChildren<unknown>) {
    const service = useRef(new NotificationService());
    const { user } = useContext(authContext);
    const eventTypes = useCachedTagListQuery('eventTypes', !!user);
    const announcementTypes = useCachedTagListQuery('announcementTypes', !!user);

    const hasNewNotifs = useQuery(
        ['notifications', 'hasnew'], service.current.hasNew,
        {
            enabled: !!user,
            staleTime: 7.5 * 60 * 1000,
            refetchInterval: 7.5 * 60 * 1000, //7.5 minutes
        }
    );

    const queryClient = useQueryClient();

    const query = useInfiniteQuery(
        ['notifications', 'list'],
        (ctx) => service.current.list(ctx.pageParam),
        {
            staleTime: 15 * 60 * 1000, //15 minutes stale time
            getNextPageParam: getListResultPageParam,
            enabled: !!hasNewNotifs.data,
            onSuccess: async (data) => {
                await wait(2000);
                const unReadNotifs = data.pages[data.pages.length - 1].items.filter(x => !x.read).map(x => x.id);

                if (unReadNotifs.length) {
                    await service.current.markAsRead(unReadNotifs);
                    queryClient.setQueryData<typeof data>(['notifications', 'list'], (prev) => ({
                        ...prev!,
                        pages: prev!.pages.map(page => {
                            return {
                                ...page,
                                items: page.items.map(x => ({ ...x, read: x.read || (unReadNotifs.some(y => y === x.id) && new Date().toISOString()) }))
                            }
                        })
                    }), { updatedAt: new Date().valueOf() });
                }

                hasNewNotifs.refetch();
            }
        }
    );

    useEffect(() => {
        if (query.data?.pages?.length && announcementTypes.data?.items && eventTypes.data?.items) {
            query.data.pages[query.data.pages.length - 1].items.forEach(item => {

                if (item.subject && item.subject.typeTags) {
                    const source = (item.subject.type === 'event' ? eventTypes : announcementTypes).data.items;

                    item.subject.typeTags = item.subject.typeTags.map(tag => source.find(x => {
                        return x.pk === tag.pk
                    })).filter(x => x) as any[];
                }
            })
        }
    }, [query.data, announcementTypes.data, eventTypes.data])


    const data = (query.data?.pages?.flatMap(x => x.items) ?? []).filter(x => !x.subject || x.subject.typeTags?.length);

    return (
        <NotificationContext.Provider value={{
            notifications: data,
            isLoading: query.isLoading || query.isRefetching || query.isFetching,
            refetch: query.refetch,
            nextPage: query.fetchNextPage,
            hasNextPage: !!query.hasNextPage,
            hasNewNotifications: !!hasNewNotifs.data
        }}>
            {children}
        </NotificationContext.Provider>
    )
}