import { zonedTimeToUtc } from "date-fns-tz";
import timezones from "../data/timezones"

export const getCurrentBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone as typeof timezones[number];

export function localeUses24HourTime(langCode: string) {
    return new Intl.DateTimeFormat(langCode, {
        hour: 'numeric'
    }).formatToParts(new Date(2020, 0, 1, 13)).find(part => part.type === 'hour')?.value.length === 2;
}


export function getUtcValueFromSeparateLocalDateAndTime(date: string, time: string = '00:00', timezone = getCurrentBrowserTimezone()) {
    const [year, month, day] = date.split('-');
    const [hour, minutes] = time.split(':');
    const value = new Date(+year, +month - 1, +day, +hour, +minutes);
    const returnVal = zonedTimeToUtc(value, timezone);
    return returnVal
}