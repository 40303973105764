import { EntityWithTagBaseService } from "../../../common/services/entityWithTag.service";
import apiService from '../../../common/services/api.service';
import { ListResult } from "../../../common/types/ListResult";
import config from '../../../../config/serverless-config.json';
import { getUtcValueFromSeparateLocalDateAndTime } from "../../../../utils/timezoneUtils";
import { addDays } from "date-fns";
import { AnnouncementDTO } from "shared-module";


export class AnnouncementsService extends EntityWithTagBaseService<AnnouncementDTO>{

    constructor() {
        super('announcements');
    }

    getPortfolioAnnouncements = async (portfolioId: string, after?: string, includeCollaborations = true) => {
        const result = await apiService.get<ListResult<AnnouncementDTO>>(this.buildUrl(), {
            params: {
                after,
                portfolioId
            }
        });
        result.data.items.forEach(this.adaptData)
        return result.data;
    }

    toggleBookmark = async (eventId: string) => {
        const result = await apiService.post<{ bookmarked: boolean }>(this.buildUrl(`${eventId}/toggle-bookmark`));
        return result.data;
    }

    search = async (filters: any, skip: string) => {
        filters = { ...filters }
        if (filters.dateStart)
            filters.dateStart = getUtcValueFromSeparateLocalDateAndTime(filters.dateStart).toISOString();
        if (filters.dateEnd)
            filters.dateEnd = addDays(getUtcValueFromSeparateLocalDateAndTime(filters.dateEnd), 1).toISOString();
        filters.skip = skip;

        const result = await apiService.post<ListResult<AnnouncementDTO>>(`/${config.stage}/search/announcements`, filters);

        result.data.items.forEach(this.adaptData)
        return result.data
    }
}