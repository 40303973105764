import config from '../../../config/serverless-config.json';

/**
 * Base service for all services
 * 
 * @param {string} entityBaseUrl The base url for the entity. For example: 'users' or 'users/{userId}/posts'
 */

export abstract class BaseService {
    protected entityBaseUrl: string;

    constructor(entityBaseUrl: string) {
        this.entityBaseUrl = entityBaseUrl;
    }

    /**
     * Will add the suffix after {stage}/{entityBase}/
     * @param {string} suffixes The rest of the url WITHOUT slash at the beginning
     */
    protected buildUrl = (...suffixes: string[]) => {
        const base = `${config.stage}/${this.entityBaseUrl}`;

        if (suffixes.map(x => x.trim()).filter(x => x).length) {
            return `${base}/${suffixes.map(x => x.trim()).join('/')}`;
        }

        return base;
    }
}