import apiService from "../../common/services/api.service"
import { BaseCRUDService } from "../../common/services/baseCRUD.service"
import { TagModel } from "../../../models/TagModel";
import { ListResult } from "../../common/types/ListResult";
import i18n from "../../../i18n";
import { TagType } from "shared-module";

export type TagApiFilters = { type: TagType };

export class TagService extends BaseCRUDService<TagModel> {

    constructor() {
        super(`/tags`)
    }

    getListByType = (filters: TagApiFilters) => {
        let lang = i18n.language;
        if (lang !== 'en' && lang !== 'fr')
            lang = 'en';
        return apiService.get<ListResult<TagModel>>(`${this.buildUrl()}/type/${filters.type}`, { params: { lang } }).then(x => x.data);
    };
}