import React, { useState } from 'react';
import { createContext, PropsWithChildren, useEffect } from "react";
import mixpanelLib, { Mixpanel } from 'mixpanel-browser';
import config from '../../../config/serverless-config.json';

const prodHostname = /app.dansebloom.com|d27qm544h7jfex.cloudfront.net/;
const isProd = () => prodHostname.test(window.location.hostname);

const getMixpanelToken = () => {
    if (isProd())
        return '64ebd516ec0ea68b81a971fe2921a32f'

    else return '99bd9f8f7c126ae66f568432bc9769d0'
}

export const MixPanelContext = createContext({ mixpanel: mixpanelLib as Mixpanel });

export const MixPanelProvider = ({ children }: PropsWithChildren<unknown>) => {
    const [mixpanel] = useState(mixpanelLib.init(getMixpanelToken(), {
        debug: !isProd(),
        cross_site_cookie: true, // @rjbrems-db - not sure if this properly removes the cookie warnings from console
    }, 'danse-bloom'));

    useEffect(() => {
        /** 
         * Add to register() the properties to inclue with every events, also called "super properties"
         * See: https://developer.mixpanel.com/docs/javascript#super-properties 
         * */

        if (!mixpanel)
            return;

        mixpanel.register({
            appVersion: config.appVersion,
        })

    }, [mixpanel]);

    return (
        <MixPanelContext.Provider value={{ mixpanel }}>
            {children}
        </MixPanelContext.Provider >
    )
}
