import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import authContext from "../../../auth/context/AuthContext";
import { PortfolioService } from "../../../portfolio/services/portfolio.service";

const I18nContext = createContext<unknown>({});

export const I18nProvider = ({ children }: PropsWithChildren<unknown>) => {
    //@ts-ignore
    const { i18n } = useTranslation();
    const { user } = useContext(authContext);
    const mutation = useMutation(new PortfolioService().setPreferedLanguage);
    const debounceRef = useRef(false);
    const debounceDelay = 1000;

    const onLanguageChange = useCallback((language: string) => {
        if (user && !debounceRef.current) {
            debounceRef.current = true;
            mutation.mutateAsync(language).finally(() => {
                setTimeout(() => {
                    debounceRef.current = false;
                }, debounceDelay);
            });
        }
    }, [user, debounceDelay]);

    useEffect(() => {
        let listener = onLanguageChange;
        i18n.on('languageChanged', listener);

        return () => {
            i18n.off('languageChanged', listener);
        }
    }, [user])

    return (
        <I18nContext.Provider value={{}}>
            {children}
        </I18nContext.Provider>
    )
}
