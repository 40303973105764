import apiService from './api.service';
import { ListResult } from '../types/ListResult';
import { convertLegacyCitytoGmap } from '../../../utils/locationUtils';
import { BaseService } from './base.service';

export abstract class BaseCRUDService<TEntity, TUpsertModel = Partial<TEntity>, TFilterModel = any> extends BaseService {

    getOne = async (id: string, queryParams?: Record<string, any>) => {
        const x = await apiService.get<TEntity>(this.buildUrl(`${id}`), { params: queryParams });
        this.adaptData(x.data);

        return x.data;
    }

    getList = async (filters?: TFilterModel, after?: string) => {
        const x = await apiService.get<ListResult<TEntity>>(this.buildUrl(), {
            params: {
                after,
                filters
            }
        });
        x.data.items.forEach(this.adaptData);
        return x.data;
    }

    getForEdit = async (id: string) => {
        const x = await apiService.get<TEntity>(this.buildUrl(`${id}/edit`));
        this.adaptData(x.data);
        return x.data;
    }

    upsert = async (entity: TUpsertModel, id?: string): Promise<TEntity> => {
        const x = await apiService.post<TEntity>(this.buildUrl(id ? id.toString() : ''), entity);
        this.adaptData(x.data);
        return x.data;
    }

    delete = (id: string) => {
        return apiService.delete(this.buildUrl(`${id}`))
    }

    protected adaptData(data: any) {
        if (data.location) {
            data.location = {
                ...data.location,
                ...convertLegacyCitytoGmap(data.location)
            }
        }
    }
}