import apiService from "../../common/services/api.service";
import config from '../../../config/serverless-config.json';

function getMe() {
    return apiService.get<{ user: any }>(`${config.apiGatewayBaseUrl}/${config.stage}/me`)
        .then(x => {
            return x.data
        });
}

export const authService = {
    getMe
}