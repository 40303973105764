import React, { createContext, PropsWithChildren, useState } from "react";
import { InfiniteData } from "react-query";
import { CastingToolSearchResultItemDTO } from "shared-module";
import { PortfolioSearchModel } from "../../../../models/PortfolioSearchModel";
import { ListResult } from "../../../common/types/ListResult";
import usePortfoliosSearchQuery from "../hooks/usePortfoliosSearchQuery";

export const PortfolioListContext = createContext({
    data: undefined as InfiniteData<ListResult<CastingToolSearchResultItemDTO>> | undefined,
    currentFilters: {} as PortfolioSearchModel,
    isLoading: false,
    isFetching: false,
    followed: false,
    onFilterChange: (filters: PortfolioSearchModel) => { return; },
    onFollowedChange: (followed: boolean) => { return; },
    loadNextPage: () => { return; },
    indexArtisticNetwork: 0,
    setIndexArtisticNetwork: (index: number) => { return; }
})

export const PortfolioListProvider = ({ children }: PropsWithChildren<unknown>) => {
    const [filters, setFilters] = useState<PortfolioSearchModel>({ portfolioType: "artist" });
    const [followed, setFollowed] = useState(false);
    const [indexArtisticNetwork, setIndexArtisticNetwork] = useState(0);

    const query = usePortfoliosSearchQuery(filters, followed);

    function handleFilterChange(filters: PortfolioSearchModel) {
        setFilters(filters);
        setFollowed(false);
    }

    function handleOnFollowedChange(followed: boolean) {
        if (followed) {
            setFilters({ portfolioType: "business" });
        }
        setFollowed(followed);
    }

    return (
        <PortfolioListContext.Provider value={{
            data: query.data,
            isLoading: query.isLoading,
            isFetching: query.isFetching,
            currentFilters: filters,
            followed,
            loadNextPage: () => query.fetchNextPage(),
            onFollowedChange: handleOnFollowedChange,
            onFilterChange: handleFilterChange,
            indexArtisticNetwork: indexArtisticNetwork,
            setIndexArtisticNetwork: setIndexArtisticNetwork,
        }}>
            {children}
        </PortfolioListContext.Provider>
    )
}