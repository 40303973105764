import React, { PropsWithChildren } from 'react'
import useModalRefAndEvents from '../../hooks/useModalRefAndEvents'
import styled from 'styled-components'
import CloseIcon from '../icons/CloseIcon/CloseIcon'

export type ModalProps = {
    show?: boolean
    headerContent?: JSX.Element
    /** If true, will display a X button in the header */
    closable?: boolean
    closableDown?: boolean
    isTransparent?: boolean
    themeColor?: string
    /** Asking if can close the modal when clicking on the backdrop or the X button (when present) */
    onCloseRequested?: () => boolean
    /** Triggered once the hide animation is finished */
    onHidden?: () => void

    width?: number | string

    centeredVertically?: boolean
    modalName?: string
    isFullScreen?: boolean
}

export default function Modal({
    show,
    children,
    headerContent,
    closable,
    closableDown,
    isTransparent,
    themeColor = 'green',
    onCloseRequested: onCloseRequest,
    onHidden,
    width,
    centeredVertically,
    modalName = '',
    isFullScreen = false
}: PropsWithChildren<ModalProps>) {
    const uniqueId = React.useRef(`modal-${Math.random()}`);
    const { divRef } = useModalRefAndEvents(!!show, onHidden, onCloseRequest, uniqueId.current);

    return (
        <div
            key={modalName + uniqueId}
            id={uniqueId.current}
            ref={divRef}
            className={`modal fade`}
            data-bs-backdrop="false"
            data-bs-keyboard="true"
            onClick={(e) => { e.stopPropagation(); }}
        >
            <div className={`modal-dialog ${centeredVertically ? 'modal-dialog-centered' : ''} ${isFullScreen && 'modal-fullscreen'}`}>

                <div
                    className="modal-content border-0 mx-auto"
                    style={{
                        borderRadius: 15,
                        maxWidth: 500,
                        width: width,
                        minWidth: '75%',
                        backgroundColor: isTransparent ? 'transparent' : 'white'
                    }}
                >
                    {(headerContent || closable) &&
                        <div className={`modal-header`} style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
                            {headerContent}
                            {closable &&
                                <CloseIcon
                                    className={`btn-close bg-white rounded-circle`}
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            }
                        </div>
                    }

                    <StyledModalBody className="modal-body">

                        {children}

                        {closableDown &&
                            <button
                                type="button"
                                className={`btn-close bg-${themeColor} rounded-circle p-2 m-3`}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        }
                    </StyledModalBody>

                </div>
            </div>
        </div>
    )
}

const StyledModalBody = styled.div({
    border: '0px',
});