import React, { createContext, PropsWithChildren, useRef, useState, useContext } from 'react'
import { useInfiniteQuery, InfiniteData } from 'react-query';
import { AnnouncementDTO } from 'shared-module'
import { ListResult } from '../../../common/types/ListResult';
import { AnnouncementsService } from '../services/announcements.service';
import AuthContext from '../../../auth/context/AuthContext';

export const AnnouncementListContext = createContext({
    data: undefined as InfiniteData<ListResult<AnnouncementDTO>> | undefined,
    currentFilters: {} as any,
    bookmarked: false,
    isLoading: false,
    onFilterChange: (filters: any) => { return; },
    onBookmarkChange: (bookmark: boolean) => { return; },
    loadNextPage: () => { return; }
})

export default function AnnouncementListProvider({ children }: PropsWithChildren<unknown>) {
    const [filters, setFilters] = useState({});
    const [bookmarked, setBookmarked] = useState(false);
    const service = useRef(new AnnouncementsService());
    const { user } = useContext(AuthContext);

    const query = useInfiniteQuery(
        ['announcements', bookmarked ? { bookmarked } : filters],
        ctx => {
            const filters: Record<string, any> = ctx.queryKey[1];
            if (filters.dateStart || filters.dateEnd || filters.announcementTypes?.length || filters.jobTypes?.length || filters.locations?.length)
                return service.current.search(ctx.queryKey[1], ctx.pageParam);
            else
                return service.current.getList(ctx.queryKey[1], ctx.pageParam);
        }, {
        getNextPageParam: (lastpage, allPages) => {
            if (lastpage.cursor)
                return lastpage.cursor;
            if (lastpage.totalCount) {
                const currentCount = allPages.reduce((prev, curr) => curr.items.length + prev, 0);
                if (currentCount < lastpage.totalCount)
                    return currentCount;
            }
            return undefined
        },
        onSuccess: (data) => {

        },
        refetchOnWindowFocus: false,
        enabled: !!user
    });

    const handleFilterChange = (filters: any) => {
        setFilters(filters);
        setBookmarked(false);
    }

    return (
        <AnnouncementListContext.Provider value={{
            currentFilters: filters,
            data: query.data,
            isLoading: query.isLoading,
            bookmarked,
            onBookmarkChange: (b) => { if (b) setFilters({}); setBookmarked(b) },
            onFilterChange: handleFilterChange,
            loadNextPage: () => {
                if (query.data?.pages[0]?.cursor)
                    query.fetchNextPage();
            }

        }}>
            {children}
        </AnnouncementListContext.Provider>
    );
}