import React from 'react'
import { styled } from 'styled-components'
import CustomSpinner from './customSpinner'

type Props = {
    size?: "sm" | "lg"
    hide?: boolean,
    isInMiddleScreen?: boolean | undefined,
    fillColor?: string
}

export default function Spinner({ size, hide, isInMiddleScreen = false, fillColor }: Props) {
    if (hide) return <></>

    return (
        <StyledContainer $isInMiddleScreen={isInMiddleScreen}>

            <CustomSpinner size={size === 'sm' ? 20 : 50} divide={size === 'sm' ? 20 : 2} baseRadius={size === 'sm' ? 1 : 2} fillColor={fillColor} />

        </StyledContainer>
    )
}

const StyledContainer = styled.div<{ $isInMiddleScreen: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.$isInMiddleScreen ? '100vh' : '100%'};
    width: 100%;
`