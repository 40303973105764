import React, { createContext, PropsWithChildren, useState } from "react";
import { CustomTypeOptions } from 'react-i18next';

export type PageTitlesKeys = keyof CustomTypeOptions['resources']['portfolio_pageTitles']
/* To add other definitions, add: 
 | keyof CustomTypeOptions['resources']['otherSection.pageTitles']
*/
export const PageTitleContext = createContext({
    title: undefined as PageTitlesKeys | string | undefined,
    setTitle: (t: PageTitlesKeys | string) => { }
})


export const PageTitleProvider = ({ children }: PropsWithChildren<unknown>) => {
    const [title, setTitle] = useState<string>();

    function handleTitleChange(newTitle: PageTitlesKeys | string) {
        document.title = newTitle.toString();
        setTitle(newTitle as string);
    }


    return (
        <PageTitleContext.Provider value={{
            title,
            setTitle: handleTitleChange
        }}>
            {children}
        </PageTitleContext.Provider>
    )
}