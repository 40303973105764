import { PortfolioLocation } from "../models/PortfolioLocation";

/**
* For backward compatibility, because it used to be:
* PortfolioLocation {
   id: string;
   provinceName: string;
   city: string;
   provinceCode: string;
   point: {
       lat: number;
       lon: number
   }
}
*/
export function convertLegacyCitytoGmap(val: any): PortfolioLocation {
    if (!!val.id && !!val.city) {
        return {
            place_id: '',
            description: `${val.city}, ${val.provinceCode}, Canada`
        }
    }
    else return val;
}