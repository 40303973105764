const wait = (timeout: number) => {
    return new Promise<void>(r => {
        setTimeout(() => {
            r();
        }, timeout);
    })
}

export default wait;

export function waitFor(callback: () => boolean, interval = 200) {
    return new Promise<void>(r => {
        const id = setInterval(() => {
            if (callback()) {
                clearInterval(id);
                r();
            }
        }, interval);
    })
}