const getHideListener = (onCloseRequest?: () => boolean) => (e: Event) => {
    const allowedToClose = !onCloseRequest || onCloseRequest();

    if (!allowedToClose)
        e.preventDefault();
}

export const ModalService = {
    /** Add a listener to bs hide event and return the said listener function */
    addHideEventListener: (modalEl: HTMLDivElement, onCloseRequest?: () => boolean) => {
        const listener = getHideListener(onCloseRequest);

        modalEl.addEventListener('hide.bs.modal', listener);

        return listener;
    },
    addHiddenListener: (modalEl: HTMLDivElement, listener?: () => void) => {
        if(listener)
            modalEl.addEventListener('hidden.bs.modal', listener);
        return listener;
    },
    removeHideEventListener: (modalEl: HTMLDivElement, listener: (e: Event) => void) => {
        modalEl.removeEventListener('hide.bs.modal', listener);
    },
    removeHiddenEventListener: (modalEl: HTMLDivElement, listener: (e: Event) => void) => {
        if(listener)
            modalEl.removeEventListener('hidden.bs.modal', listener);
    }

}