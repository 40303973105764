import React, { PropsWithChildren, useEffect, useState } from "react";
import { UserModel } from "../../../models/UserModel";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/auth.service";
import { useToast } from "../../common/hooks/useToast";
import useMixpanel from "../../common/hooks/useMixPanel";
import CognitoService from "../services/cognito.service";
import wait from "../../../utils/wait";
import { SessionStorage } from "../../../utils/sessionStorage";
import { useTranslation } from "react-i18next";
import { MarketplaceBuyerService } from "../../common/services/marketplaceBuyer.service";
import { useQueryClient } from "react-query";
import { PortfolioService } from "../../portfolio/services/portfolio.service";

export default function AuthProvider({ children }: PropsWithChildren<unknown>) {
    const [user, setUser] = useState<UserModel>();
    const [isLoading, setIsLoading] = useState(true);
    const { toast } = useToast();
    const navigate = useNavigate();
    const mixpanel = useMixpanel();
    //@ts-ignore
    const { t } = useTranslation(['toasts']);
    const queryClient = useQueryClient();

    useEffect(() => {
        (async () => {
            try {
                const session = await CognitoService.instance.getSession();

                if (session) {
                    const user = await refetchUser();
                    mixpanel.identify(user?.userId ?? user?.portfolio?.userId);
                    if (user?.portfolio?.username) {
                        mixpanel.register({
                            profileUrl: `${window.location.origin}/db/u/${user?.portfolio?.username}`
                        })
                    }

                    mixpanel.track('Auth - User opens the app');
                }
            } catch (err: any) {
                if (err.code === 'NotAuthorizedException') {
                    setUser(undefined);
                    navigate('/login');
                }
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    async function login(email: string, password: string) {
        const result = await CognitoService.instance.login(email, password);

        if (result.userConfirmationNecessary) {
            navigate(`/confirm-email?email=${encodeURIComponent(email)}`);
        } else if (result.newPasswordRequired) {
            navigate(`/force-reset-password?email=${encodeURIComponent(email)}`)
        } else if (result.session) {
            try {
                await wait(200);
                const user = await refetchUser();
                mixpanel.identify(user?.userId ?? user?.portfolio?.userId);
                if (user?.portfolio?.username) {
                    mixpanel.register({
                        profileUrl: `${window.location.origin}/db/u/${user?.portfolio?.username}`
                    })
                }
                mixpanel.track('Auth - Login');

                //Using a set timeout to allow the setUser (from refetch user) to run before the redirect
                setTimeout(() => {
                    MarketplaceBuyerService.assignGuestTransactionToUser();
                    const redirectTo = SessionStorage.getRedirectRoute();
                    SessionStorage.clearRedirectRoute();
                    if (redirectTo !== null)
                        navigate(redirectTo, { replace: true });
                }, 100)
            } catch (Err) {
                console.error(Err)
                navigate('/login')
            }
        }
    }

    async function logout() {
        await CognitoService.instance.logout();
        setUser(undefined);

        mixpanel.track('Auth - Logged out');
        mixpanel.identify(undefined);

        toast({ type: 'success', message: t('toasts:logout') });
        navigate('/login');
    }


    async function refetchUser() {
        try {
            const { user } = await authService.getMe();
            setUser(user);

            if (user?.portfolio)
                localStorage.setItem('avatarUrl', user.portfolio.avatarCroppings?.circle?.url ?? user.portfolio.avatarUrl);

            queryClient.invalidateQueries(['portfolios', 'events', 'announcements']);

            return user;
        } catch {
            setUser(undefined);
        }
    }

    async function markGuidedTourAsRead(tour: keyof UserModel['onboarding']) {
        if (!user) return;

        const timestamp = new Date().toISOString();
        const updatedOnboarding = { ...user.onboarding, [tour]: timestamp };
        setUser({ ...user, onboarding: updatedOnboarding });

        try {
            await new PortfolioService().updateOnboarding(user.userId, { [tour]: timestamp });
        } catch {
            setUser(undefined);
        }
    }

    return (
        <AuthContext.Provider value={{
            user,
            logout,
            isLoading,
            refetchUser,
            markGuidedTourAsRead,
            login,
        }}>
            {children}
        </AuthContext.Provider>
    )
}