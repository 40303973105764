import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { PortfolioSearchModel } from "../../../../models/PortfolioSearchModel";
import { PortfolioAppContext } from "../../context/PortfolioAppContext";
import authContext from "../../../auth/context/AuthContext";

// This is the hook that is used in the PortfolioList component
// It is used to retrieve portfolios from the backend

export default function usePortfolioSearchQuery(filters: PortfolioSearchModel, followed = false) {
    const { user } = useContext(authContext);
    const { portfolioService } = useContext(PortfolioAppContext);

    const query = useInfiniteQuery(
        ['portfolios', 'list', followed ? 'followed' : filters],
        ctx => {
            const filters = ctx.queryKey[2] as PortfolioSearchModel | 'followed';

            if (filters === 'followed') {
                // Retrieve followed accounts
                return portfolioService!.getFollowedAccounts(ctx.pageParam);
            } else {
                const titleFilter = filters.portfolioType === 'business' ? filters.businessTitle : filters.title;
                const filterCount = (filters.style?.length ?? 0) + (titleFilter?.length ?? 0) + (filters.locations?.length ?? 0);

                if (filterCount === 0 && filters.portfolioType === 'artist') {
                    // List all portfolios
                    return portfolioService!.list(ctx.pageParam);
                } else {
                    // Search portfolios based on filters
                    return portfolioService!.search(ctx.queryKey[2] as PortfolioSearchModel, ctx.pageParam);
                }
            }
        },
        {
            getNextPageParam: (lastpage, allPages) => {
                if (lastpage.cursor) {
                    // Get the next page using cursor
                    return lastpage.cursor;
                }

                if (lastpage.totalCount) {
                    const currentCount = allPages.reduce((prev, curr) => curr.items.length + prev, 0);

                    if (currentCount < lastpage.totalCount) {
                        // Get the next page using current count
                        return currentCount;
                    }
                }

                return undefined;
            },
            refetchOnWindowFocus: false,
            enabled: !!user,
        }
    );

    return query;
}