import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './custom.scss';
import './i18n';
import { init, browserTracingIntegration } from '@sentry/react';
import config from './config/serverless-config.json';

if (config.stage === 'prod') {
    init({
        dsn: "https://6ba0416038d3f244243cc6e5cd999c3c@o4507209467494400.ingest.us.sentry.io/4507209469591552",
        integrations: [
            browserTracingIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/app\.dansebloom\.(com|ca)/],
    });
}

const Root = () => {

    return (
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    );
};

createRoot(document.getElementById('root')!).render(<Root />);
