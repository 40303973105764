import apiService from "./api.service"
import config from "../../../config/serverless-config.json";
import { SellerAccountDTO } from "shared-module";


export class MarketplaceSellerService {

    /**
     * Will add the suffix after {stage}/{entityBase}/
     * @param {string[]} suffixes The rest of the url WITHOUT slash at the begining
     */
    protected static buildUrl = (...suffixes: string[]) => {
        if (!suffixes.map(x => x.trim()).filter(x => x).length)
            return `${config.stage}/marketplace/`;

        return `${config.stage}/marketplace/${suffixes.map(x => x.trim()).join('/')}`;
    }


    static getOnboardingLink = async ({ refreshUrl, returnUrl }: { refreshUrl: string, returnUrl: string }) => {

        return apiService.post(this.buildUrl('onboarding'), {
            refreshUrl,
            returnUrl
        }).then(x => x.data.link as string);
    }

    static getSellerAccount = async () => {
        return apiService.get<SellerAccountDTO>(this.buildUrl('account'))
            .then(x => x.data);
    }

    static getQuantitySold = async (eventId: string) => {
        return apiService.get<{ quantities: Record<string, number> }>(this.buildUrl('events', eventId, 'quantity-sold')).then(x => x.data);
    }
}