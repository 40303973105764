import React from 'react'
import { PropsIcon } from '../IconProps/IconProps';

const Icon = require('../svg/default/db_icon_close.svg').ReactComponent;

export default function CloseIcon({ size = 28, ...props }: PropsIcon) {

    return (
        <Icon
            width={size}
            height={size}
            alt="Close Icon"
            {...props}
        />
    )
}