export const SessionStorage = {
    setRedirectRoute: (route: string) => {
        return sessionStorage.setItem('requestedRoute', route.trim())
    },

    getRedirectRoute: () => {
        return sessionStorage.getItem('requestedRoute')
    },

    clearRedirectRoute: () => {
        return sessionStorage.removeItem('requestedRoute')
    },

}