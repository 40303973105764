import { useRef, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { TagType } from "shared-module";
import { TagModel } from "../../../models/TagModel";
import { TagService } from "../../portfolio/services/tag.service";
import { ListResult } from "../types/ListResult";
import authContext from "../../auth/context/AuthContext";

export function useCachedTagListQuery(tagType: TagType, enabled = true) {
    // @ts-ignore
    const { i18n } = useTranslation();
    const service = useRef(new TagService());
    const queryClient = useQueryClient();
    const { user } = useContext(authContext);

    const queryKey = useMemo(() => {
        return ['tags', tagType, i18n.language] as const;
    }, [tagType, i18n.language]);

    return useQuery<
        ListResult<TagModel>,
        unknown,
        ListResult<TagModel>,
        typeof queryKey
    >({
        queryKey: queryKey,
        queryFn: (ctx) => service.current.getListByType({ type: ctx.queryKey[1] }),
        initialData: () => queryClient.getQueryData<ListResult<TagModel>>(queryKey),
        initialDataUpdatedAt: () => queryClient.getQueryState(queryKey)?.dataUpdatedAt,
        staleTime: 15 * 60 * 1000, //(15 minutes)
        enabled: enabled && !!user
    });
}