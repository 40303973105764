import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ToastModel, useToastDispatchContext } from "../contexts/toastContext/ToastContext";
import { styled } from "styled-components";

export function useToast() {
    //@ts-ignore
    const { t } = useTranslation('toasts');
    const dispatch = useToastDispatchContext();

    function toast(config: Omit<ToastModel, 'id'>) {
        const id = Math.random().toString(36).substring(2, 9);
        dispatch!({
            type: 'ADD_TOAST',
            toast: { id, ...config } as ToastModel
        });
    }

    function toastSaveSuccess() {
        const id = Math.random().toString(36).substring(2, 9);
        dispatch!({
            type: 'ADD_TOAST',
            toast: {
                id,
                type: 'success',
                message: t('saved'),
                delay: 6000
            }
        });
    }

    function toastSaveError() {
        const id = Math.random().toString(36).substring(2, 9);
        dispatch!({
            type: 'ADD_TOAST',
            toast: {
                id,
                type: 'danger',
                message: t('error'),
                delay: 6000
            }
        });
    }

    function toastPortfolioAlreadyInList() {
        const id = Math.random().toString(36).substring(2, 9);
        dispatch!({
            type: 'ADD_TOAST',
            toast: {
                id,
                type: 'info',
                message: t('portfolio.isAlreadyInList'),
                delay: 6000
            }
        });
    }

    function toastPortfolioAdded(url: string) {
        const id = Math.random().toString(36).substring(2, 9);
        dispatch!({
            type: 'ADD_TOAST',
            toast: {
                id,
                type: 'success',
                message: <div className="d-flex justify-content-between align-items-center w-100">{t('portfolio.added')}<StyledLink to={url} className="text-dark ms-2 px-2 py-1" >Go to list</StyledLink></div>,
                delay: 6000
            }
        });
    }

    return {
        toast,
        toastSaveSuccess,
        toastSaveError,
        toastPortfolioAlreadyInList,
        toastPortfolioAdded
    };
}

const StyledLink = styled(Link)`
    color: var(--db-black);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;